import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { useTranslation } from "react-i18next"
import "../../i18n.js";

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogBanner from "../components/blog-banner/blogBanner"
import aboutBanner from '../images/about-banner.gif';

const AboutPage = () => {
  const { t } = useTranslation()

  return (
    <Layout navbarTheme="light" className="bg-blue">
      <Seo title={t("metaAboutTitle")} description={t("metaAboutDescription")} canonical="https://tio.ist/about/" />
      <div className="mt-28 lg:mt-32">
        <div className="mb-16 lg:mb-24">
          <h1 className="mx-5 md:mx-16 text-6xl md:text-8xl lg:text-9xl font-extrabold text-white">
            {t("aboutTitle")}
          </h1>
         {/*  <p className="ticker-wrap-v2 bg-transparent text-white text-6xl md:text-8xl lg:text-9xl font-light italic">
            <div className="ticker z-10 h-[3.75rem] md:h-24 lg:h-32">
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
            </div>
            <div className="ticker z-10 h-[3.75rem] md:h-24 lg:h-32">
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("aboutTickerTitle")}</p>
              </div>
            </div>
          </p> */}

          <div className="overflow-hidden flex text-cream text-6xl md:text-8xl lg:text-9xl font-light italic">
              <div className="flex flex-none items-center min-w-[125%] md:min-w-full animate-scroll-left">
                <p>{t("aboutTickerTitle")}</p>
              </div>
              <div className="flex flex-none items-center min-w-[125%] md:min-w-full animate-scroll-left">
                <p>{t("aboutTickerTitle")}</p>
              </div>
            </div>
        </div>

        <div className="flex justify-end mx-5 md:mx-14">
          <p className="text-cream text-xl lg:text-3xl max-w-[15rem] md:max-w-md lg:max-w-3xl">
            {t("aboutDescription")}
          </p>
        </div>

        <div className="mx-5 md:mx-14 mt-16 md:mt-32 mb-16 lg:mb-36">
          <div className="w-full">
            <img src={aboutBanner} alt="tio" className="w-full" />
          </div>
        </div>

        <div className="mx-auto max-w-xs md:max-w-lg lg:max-w-3xl mb-28 md:mb-32 lg:mb-48">
          <h2 className="text-white text-6xl md:text-8xl lg:text-9xl">
            <span className="block font-bold text-left">
              {t("aLook")}
            </span>
            <span className="block italic text-right">
              {t("toUs")}
            </span>
          </h2>

          <p className="text-lightBlue md:text-lg lg:text-xl text-center pt-16 lg:pt-36">
            {t("inNumbers")}
          </p>
        </div>

        <div className="space-y-32 md:space-y-64">
          <div className="md:max-w-2xl lg:max-w-4xl mx-5 md:mx-auto">
            <div className="flex justify-end">
              <div className="flex flex-col">
                <div className="inline-flex">
                  <p className="font-bold text-8xl lg:text-9xl text-white">1</p>
                  <p className="text-lightBlue font-normal text-lg lg:text-3xl">
                    ST
                  </p>
                </div>
                <p className="text-lightBlue text-lg lg:text-xl">
                  {t("oneOfTheFirst")}
                </p>
              </div>
            </div>
            <div className="max-w-[12rem] md:max-w-md lg:max-w-lg mt-16 md:mt-0">
              <p className="text-cream text-xl lg:text-3xl">
                {t("aboutScrollDescription1")}
              </p>
            </div>
          </div>

          <div className="md:max-w-2xl lg:max-w-4xl mx-5 md:mx-auto">
            <div className="flex">
              <div className="flex flex-col">
                <div className="inline-flex">
                  <p className="font-bold text-8xl lg:text-9xl text-white">
                    20
                  </p>
                  <p className="text-lightBlue font-normal text-lg lg:text-3xl">
                    +
                  </p>
                </div>
                <p className="text-lightBlue text-lg lg:text-xl">
                  {t("productsLaunched")}
                </p>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="max-w-[12rem] md:max-w-md lg:max-w-lg mt-16 md:mt-0">
                <p className="text-cream text-xl lg:text-3xl">
                  {t("aboutScrollDescription2")}
                </p>
              </div>
            </div>
          </div>

          <div className="md:max-w-2xl lg:max-w-4xl mx-5 md:mx-auto">
            <div className="flex justify-end">
              <div className="flex flex-col">
                <div className="inline-flex">
                  <p className="font-bold text-8xl lg:text-9xl text-white">
                    50
                  </p>
                  <p className="text-lightBlue font-normal text-lg lg:text-3xl">
                    +
                  </p>
                </div>
                <p className="text-lightBlue text-lg lg:text-xl">{t("companies")}</p>
              </div>
            </div>
            <div className="max-w-[12rem] md:max-w-md lg:max-w-lg mt-16 md:mt-0">
              <p className="text-cream text-xl lg:text-3xl">
                {t("aboutScrollDescription3")}
              </p>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-[16rem] md:max-w-md lg:max-w-2xl mt-32 md:mt-64">
          <p className="text-center text-cream text-xl lg:text-3xl">
            {t("aboutDescription2")}
          </p>
        </div>

        <div className="mx-5 md:mx-16 flex flex-col md:flex-row space-y-20 md:space-y-0 md:space-x-14 mt-32 md:mt-36 lg:mt-64">
          <div className="w-2/3 md:w-1/3">
            <p className="text-white text-xl lg:text-3xl">
              <span className="block font-bold">{t("wantTo")}</span>
              <span className="block italic">{t("workWithUs")}</span>
            </p>
            <p className="text-white text-lg whitespace-pre-line mt-16">
              {t("workWithUsDescription")}
            </p>
          </div>
          <div className="flex md:block justify-end md:w-1/2">
            <div className="w-4/5 md:w-full relative group">
              <div>
                <StaticImage src="../images/about-us.jpg" alt="workspace" formats={["auto", "webp", "avif"]} />
              </div>
              <a href="https://tr.indeed.com/tio-jobs-in-Istanbul" target="_blank" rel="noreferrer" className="md:hidden md:group-hover:block absolute right-1/2 translate-x-1/2 md:translate-x-0 md:-right-8 lg:-right-40 top-1/2 -translate-y-1/2 whitespace-nowrap">
                <span className="group text-white text-6xl md:text-8xl lg:text-9xl font-bold">
                  <span className="border-b-2 border-transparent group-hover:border-white">{t("applyCTA")}</span>{" "}
                  <svg className="inline-block w-8 h-8 md:w-10 md:h-12 lg:w-16 lg:h-16 align-top pt-1 md:pt-3" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.43182 36.522L0 31.0902L22.9318 8.13565H5.70455L5.75 0.726562H35.7727V30.772H28.3182L28.3636 13.5675L5.43182 36.522Z" fill="white" />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-64 relative">
          <div>
            <StaticImage src="../images/tio-led.jpg" alt="workspace" formats={["auto", "webp", "avif"]} />
          </div>
          <Link to="/studio" className="group text-cream absolute top-8 md:top-12 left-5 md:left-10 text-sm md:text-xl"><span className="border-b-2 border-transparent group-hover:border-white">{t("moreInfo")}</span> →</Link>
          <Link to="/studio" className="group text-cream absolute top-8 md:top-12 right-5 md:right-10 text-sm md:text-xl">
            <span className="border-b-2 border-transparent group-hover:border-white">{t("readAboutTheStudio")}</span>{" "}
            <svg className="inline-block align-top w-3 h-3 md:w-5 md:h-5 pt-1 md:pt-2" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.21875 11.9844L0 10.7656L9.0625 1.6875H2.0625L2.07812 0H11.9688V9.90625H10.2656L10.2812 2.90625L1.21875 11.9844Z" fill="#FBF7EE" />
            </svg>
          </Link>
        </div>
      </div>
      <BlogBanner />
    </Layout>
  )
}

export default AboutPage


